import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">

      <div className="row">
        <div className="col-sm mt-auto">
          <h1 className="mt-4">Page Not Found</h1>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm">
          <p>That just doesn't seem right... but there really is no page here!</p>
        </div>

      </div>

    </div>
  </Layout>
)

export default NotFoundPage
